import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimezoneFix',
  standalone: false
})
export class DateTimezoneFixPipe implements PipeTransform {
  transform(value: string, format: string): string {
    const date = moment(value);
    if (date.hour() > 21) {
      date.add(1, 'day');
      date.startOf('day');
    }
    return date.format(format || 'DD.MM.YYYY');
  }
}
