<ci-sub-header title="Current and previous imports"></ci-sub-header>

<div class="upload-field">
  <coin-action-button mat-raised-button ngClass="primary" svgIcon="upload" (click)="openUploadPopup()"> Upload </coin-action-button>
</div>
<ci-simple-table
  [header]="selectedTableHeaders"
  [service]="selectedTableService"
  [serverPaginated]="true"
  [serverside]="true"
  [presetQueryParams]="queryParameters$ | async"
  [isExpectedToChange]="isExpectedToChange"
  [updaterIntervalInMs]="updaterIntervalInMs">
  <ng-template #columns let-item>
    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <i class="edit-button material-icons" (click)="loadSummary(item)" *ngIf="!isExpectedToChange(item.state); else progressing">{{
            item.state == 'Staged' ? 'edit' : 'visibility'
          }}</i>
          <ng-template #progressing>
            <p *ngIf="item.message" #tooltip="matTooltip" matTooltip="{{ item.message.split('/')[2] }}" matTooltipPosition="below">{{ getProgressPercentage(item.message) }} %</p>
            <i *ngIf="!item.message && isStaging(item)" (click)="loadSummary(item)" class="edit-button in-progress material-icons">downloading</i>
            <i *ngIf="!item.message && isPending(item)" class="edit-button in-progress material-icons" matTooltip="Open PopUp" (click)="openJumpPopup(item)">double_arrow</i>

            <i *ngIf="!item.message && isPostStaging(item)" (click)="loadSummary(item)" class="edit-button in-progress material-icons">publish</i>
          </ng-template>
        </div>
      </div>

      <div class="content-column">
        <div class="row" *ngIf="item">
          <p [class.error]="item.state == 'Failed'">
            {{ item.fileName | fileName }}
          </p>
        </div>
      </div>

      <div class="content-column">
        <div class="ps-wrapper">
          <div *ngIf="item">
            <p class="ps ps--finalized">{{ item.state }}</p>
          </div>
        </div>
      </div>

      <div class="content-column">
        <div class="row" *ngIf="item">
          <mat-icon>calendar_today</mat-icon>
          <p [class.error]="item.state == 'Failed'">
            {{ item.uploadedAt | date: 'MMM d, y' }}
            <br />
            {{ item.uploadedAt | date: '(HH:mm, z)' : 'UTC' }}
          </p>
        </div>
      </div>

      <div class="content-column">
        <div *ngIf="item && item.fileType">
          <p [class.error]="item.state == 'Failed'">
            {{ item.fileType.displayedName }}
          </p>
        </div>
      </div>

      <div class="content-column">
        <div>
          <p *ngIf="item && item.stats && item.stats.totalDatasets >= 0" [class.error]="item.state == 'Failed'">{{ item.stats.totalDatasets | number: '1.0' }} datasets</p>
          <p [class.error]="item.state == 'Failed'">
            {{ item.uploadSizeInBytes | fileSize }}
          </p>
        </div>
      </div>

      <div class="content-column" [class.pending]="isNotReadyState(item)">
        <div *ngIf="item && item.stats && item.stats.changedDatasets >= 0">
          <p [class.error]="item.state == 'Failed'">
            {{ item.stats.changedDatasets | number: '1.0' }} datasets ({{ item.stats.changedDatasets | percentage: item.stats.totalDatasets }}
            of total)
            <br />
            {{ item.stats.deltaSizeInBytes | fileSize }}
          </p>
        </div>
      </div>

      <div class="content-column" [class.pending]="isNotReadyState(item)">
        <div *ngIf="item && item.stats && item.stats.invalidDatasets >= 0">
          <p [class.error]="item.state == 'Failed'">
            {{ item.stats.invalidDatasets | number: '1.0' }} datasets ({{ item.stats.invalidDatasets | percentage: item.stats.changedDatasets }}
            of changed)
          </p>
        </div>
      </div>

      <div class="content-column" [class.pending]="isNotReadyState(item)">
        <div class="row" *ngIf="item?.decision?.decidedBy">
          <i class="material-icons cr-icon">person</i>
          <p [class.error]="item.state == 'Failed'">
            {{ getNameFromDecision(item.decision) }}<br />
            (GID: {{ item.decision?.decidedBy?.gid }})
          </p>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noData>
    <p>no data</p>
  </ng-template>
</ci-simple-table>
