<div class="row">
  <ci-simple-table class="mat-elevation-z4" [header]="tableHeader" [externalDataInput$]="fileTypeService.fileTypeSubject$" [hidePagination]="true" [loading]="loading">
    <ng-template #columns let-item>
      <div class="content-column" *ngIf="item">
        <div class="row" *ngIf="item">
          <div class="edit-menu-items mat-elevation-z4">
            <i class="edit-button material-icons" (click)="openEditFileTypePopup(item)">edit </i>
          </div>
          <div class="edit-menu-items mat-elevation-z4">
            <i class="edit-button material-icons" (click)="openDeleteFileTypePopup(item)">delete_forever </i>
          </div>
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.displayedName }}
          </p>
        </div>
      </div>

      <div class="content-column">
        <div class="ps-wrapper">
          <div *ngIf="item">
            <p class="ps ps--finalized">{{ item.fileFormat }}</p>
          </div>
        </div>
      </div>
      <div class="content-column ps-wrapper">
        <div class="row" *ngIf="item">
          <p>{{ item.exportAttributes.length }}</p>
          <i class="edit-button material-icons" (click)="openEditAttributesPopup(item)">edit </i>
        </div>
      </div>
      <div class="content-column">
        <div class="ps-wrapper">
          <div *ngIf="item">
            <p class="ps ps--finalized">{{ getThreshold(item) ?? 'none' }}</p>
          </div>
        </div>
      </div>
      <div class="content-column">
        <div class="ps-wrapper">
          <div *ngIf="item">
            <p class="ps ps--finalized">{{ item.retentionTime || 'none' }}</p>
          </div>
        </div>
      </div>
      <div class="content-column">
        <div class="ps-wrapper">
          <div *ngIf="item">
            <i class="email-button material-icons" *ngIf="item.emailAddress && item.emailAddress.length > 0">alternate_email </i>
            <p class="ps ps--finalized" *ngIf="!item.emailAddress || !item.emailAddress.length">none</p>
          </div>
        </div>
      </div>
      <div class="content-column">
        <div class="ps-wrapper" *ngIf="item">
          <coin-action-button (click)="updateCustomValidation(item)"> Edit </coin-action-button>
        </div>
      </div>
    </ng-template>
    <ng-template #noData>
      <p>no data</p>
    </ng-template>
  </ci-simple-table>
</div>
